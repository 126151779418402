import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ServicesComp from './servicescomp';
import TestimonialsAndSuggestions from './testimonials';
import './home.css';

const Home = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [address, setAddress] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [typeName, setTypeName] = useState('');
  const [brand, setBrand] = useState('');
  const [carType, setCarType] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [carTypes, setCarTypes] = useState([]);

  const [showBrandPopup, setShowBrandPopup] = useState(false);
  const [showModelPopup, setShowModelPopup] = useState(false);

  const addressInputRef = useRef(null);

  const fetchCarTypes = (typeId) => {
    fetch(`https://lumolimited.com/backend/vehicles/type/${typeId}/brands-or-car-types`)
      .then(response => response.json())
      .then(data => setCarTypes(data))
      .catch(error => console.error("Error fetching car types:", error));
  };


  // Hardcoded brands and models
  const hardcodedBrands = [
    { id: 1, name: 'Maruti', img: 'images/car-brands/Maruti.png' },
    { id: 2, name: 'Hyundai', img: 'images/car-brands/Hyundai.png' },
    { id: 3, name: 'Honda', img: 'images/car-brands/Honda.png' },
    { id: 4, name: 'Audi', img: 'images/car-brands/Audi.png' },
    { id: 5, name: 'Bentley', img: 'images/car-brands/bentley.png' },
    { id: 6, name: 'Tata', img: 'images/car-brands/Tata.png' },
    { id: 7, name: 'Cadillac', img: 'images/car-brands/Cadillac.png' },
    { id: 8, name: 'Chevrolet', img: 'images/car-brands/Chevrolet.png' },
    { id: 9, name: 'chrysler', img: 'images/car-brands/chrysler.png' },
    { id: 10, name: 'Ferrari', img: 'images/car-brands/Ferrari.png' },
    { id: 11, name: 'Fiat', img: 'images/car-brands/fiat.png' },
    { id: 12, name: 'Ford', img: 'images/car-brands/Ford.png' },
    { id: 13, name: 'GMC', img: 'images/car-brands/gmc.png' },
    { id: 14, name: 'Hummer', img: 'images/car-brands/Hummer.png' },
    { id: 15, name: 'Isuzu', img: 'images/car-brands/Isuzu.png' },
    { id: 16, name: 'Jaguar', img: 'images/car-brands/Jaguar.png' },
    { id: 17, name: 'Jeep', img: 'images/car-brands/Jeep.png' },
    { id: 18, name: 'kia', img: 'images/car-brands/kia.png' },
    { id: 19, name: 'Lamborghini', img: 'images/car-brands/Lamborghini.png' },
    { id: 20, name: 'landrover', img: 'images/car-brands/landrover.png' },
    { id: 21, name: 'Lexus', img: 'images/car-brands/Lexus.png' },
    { id: 22, name: 'Volkswagen', img: 'images/car-brands/Volkswagen.png' },
    { id: 23, name: 'Mahindra', img: 'images/car-brands/Mahindra.png' },
    { id: 24, name: 'Renault', img: 'images/car-brands/Renault.png' },

  ];

  const hondaModels = [
    { name: 'Civic', img: 'images/car-models/honda-civic.png' },
    { name: 'Sedan', img: 'images/car-models/honda-sedan.png' },
    { name: 'SUV', img: 'images/car-models/honda-suv.png' },
    { name: 'Vision XS-1', img: 'images/car-models/honda-vision-xs1.png' },
  ];

  // State for the search input
  const [searchQuery, setSearchQuery] = useState("");

  // Filter brands based on the search query
  const filteredBrands = hardcodedBrands.filter((brand) =>
    brand.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAJuqCQT3_uInZBGaSm0fu5pFEETjoYKNU&libraries=places`;
      document.body.appendChild(script);
    };

    const initializeAutocomplete = () => {
      if (addressInputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
          types: ['address'],
          componentRestrictions: { country: 'IN' },
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            setAddress(place.formatted_address);
            setLocation({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          }
        });
      }
    };

    loadGoogleMapsScript();

    fetch('https://lumolimited.com/backend/vehicles/type')
      .then((response) => response.json())
      .then((data) => setVehicleTypes(data))
      .catch((error) => console.error('Error fetching vehicle types:', error));

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
        fetchAddress(latitude, longitude);
      },
      (error) => console.error('Error fetching location:', error),
      { enableHighAccuracy: true }
    );
  }, []);

  const fetchAddress = async (latitude, longitude) => {
    const API_KEY = 'AIzaSyAJuqCQT3_uInZBGaSm0fu5pFEETjoYKNU';
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
      );
      if (!response.ok) throw new Error(`Network response was not ok, status: ${response.status}`);
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress(`Error: ${data.status} - ${data.error_message || 'No results found'}`);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setAddress('Error fetching address');
    }
  };

  const handleVehicleTypeChange = (e) => {
    const selectedIndex = e.target.selectedIndex; // Get the selected index
    setVehicleType(e.target.value); // Set the selected value
    setTypeName(e.target.options[selectedIndex].text); // Set the selected text

    // Check if the selected index is 0 and show the popup
    if (selectedIndex === 1) {
      setShowBrandPopup(true);
    } else {
      setShowBrandPopup(false);
    }
  };

  const handleBrandClick = (selectedBrand) => {
    setBrand(selectedBrand.name);
    if (selectedBrand.name === 'Honda') {
      setShowBrandPopup(false);
      setShowModelPopup(true);
    }
  };

  const handleBackToBrands = () => {
    setShowModelPopup(false);
    setShowBrandPopup(true);
  };


  const handleSubmit = () => {
    navigate('/serviceproviders', {
      state: {
        vehicleTypeId: vehicleType,
        vehicleTypeName: typeName,
        brandId: brand,
        brandName: brands.find((b) => b.id === brand)?.brandName || '',
        carType: typeName.toLowerCase() === 'car' ? carType : null,
        latitude: location.lat,
        longitude: location.lng,
        address,
      },
    });
  };

  return (
    <div id="home" className="who-we-are-main-block mt-20">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="section" style={{ paddingTop: '40px', width: '100%' }}>
              <h2 className="section-heading" style={{ width: '95%' }}>Wash Anywhere with Lumo</h2>
              <h5 style={{ textTransform: 'capitalize', marginTop: '10px', color: '#444', lineHeight: '2.6', width: '80%' }}>
                Select your vehicle type
              </h5>
              <select
                name="vehicleType"
                className="form-control responsive-input"
                value={vehicleType}
                onChange={handleVehicleTypeChange}
              >
                <option value="" disabled>Select Vehicle Type</option>
                {vehicleTypes.length > 0 ? (
                  vehicleTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typeName || 'Unnamed Type'}
                    </option>
                  ))
                ) : (
                  <option disabled>Loading vehicle types...</option>
                )}
              </select>


              {/* Popup for Brands */}
              {/* Popup for Brands */}
              {showBrandPopup && (
                <div className="popup">
                  {/* Close Button */}
                  <button className="popup-close-btn" onClick={() => setShowBrandPopup(false)}>
                    ×
                  </button>

                  {/* Title */}
                  <h4>Select a Brand</h4>

                  {/* Search Box */}
                  <input
                    type="text"
                    placeholder="Search for a brand..."
                    className="brand-search-box"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Dynamically updates search query
                  />
                  <br />

                  {/* Filtered Brand Grid */}
                  <div className="popup-grid">
                    {filteredBrands.length > 0 ? (
                      filteredBrands.map((brand) => (
                        <div
                          key={brand.id}
                          className="brand-item"
                          onClick={() => handleBrandClick(brand)}
                        >
                          <img src={brand.img} alt={brand.name} />
                          <p>{brand.name}</p>
                        </div>
                      ))
                    ) : (
                      <p>No brands found.</p> // Fallback message when no match
                    )}
                  </div>
                </div>
              )}



              {/* Popup for Models */}
              {showModelPopup && (
                <div className="popup">
                  <button onClick={handleBackToBrands}>Back to Brands</button>
                  <h3>Select a Model</h3>
                  <div className="popup-grid">
                    {hondaModels.map((model, index) => (
                      <div key={index} className="model-item">
                        <img src={model.img} alt={model.name} />
                        <p>{model.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}



              <input
                type="text"
                name="location"
                ref={addressInputRef}
                className="form-control responsive-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter your location..."
              />
              <input
                type="submit"
                className="btn btn-default btn-over color-9 special"
                value="Submit"
                style={{ paddingTop: '10px', width: '30%', textAlign: 'center', marginLeft: '0' }}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="who-we-are-img">
              <img src="images/carpic-rightside.png" className="img-fluid floating-dock" alt="Car on the right side" />
            </div>
          </div>
        </div>
      </div>

      <div id="services"><ServicesComp /></div>
      <div id="testimonials"><TestimonialsAndSuggestions /></div>
    </div>
  );
};

export default Home;
