import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import "./signup.css";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    role: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission and send POST request to API
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("JSON data being sent:", formData); // Log the data

    try {
      const response = await fetch("https://lumolimited.com/backend/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set header to JSON
        },
        body: JSON.stringify(formData), // Directly stringify the formData object
      });

      console.log("Response status:", response.status); // Log response status
      const responseBody = await response.json(); // Parse JSON response

      console.log("Response body:", responseBody); // Log the parsed response

      if (response.ok) {
        alert(responseBody.message || "User registered successfully.");
      } else {
        alert(responseBody.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("Server error. Please try again later.");
    }
  };

  // Handle Google login
  const handleGoogleLogin = () => {
    window.location.href = "https://lumolimited.com/auth/google";
  };

  // Handle Facebook login
  const handleFacebookLogin = () => {
    window.location.href = "https://lumolimited.com/auth/facebook";
  };

  return (
    <Box className="signup-form" style={{ paddingTop: "65px" }}>
      <Typography variant="h5" className="signup-title mb-5">
        Create an Account
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              required
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              required
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              required
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              fullWidth
              required
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              fullWidth
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
              fullWidth
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              fullWidth
              className="signup-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              fullWidth
              className="signup-input"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className="signup-button"
        >
          Sign Up
        </Button>
      </form>
      <Typography variant="body1" className="social-login-text">
       
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleGoogleLogin}
        className="social-login-button google"
        style={{display: "none"}}
      >
        Google Login
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleFacebookLogin}
        className="social-login-button facebook"
        style={{display: "none"}}
      >
        Facebook Login
      </Button>
    </Box>
  );
};

export default Signup;
