import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import "./login.css";

const Login = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch("https://lumolimited.com/backend/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          });
      
          const data = await response.json();
      
          if (response.ok) {
            // Store tokens in localStorage or cookies
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
      
            alert("Login Successful!");
          } else {
            alert(data.message || "Login Failed!");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Server error.");
        }
      };
      

    const handleGoogleLogin = () => {
        window.location.href = "https://lumolimited.com/auth/google";
    };

    const handleFacebookLogin = () => {
        window.location.href = "https://lumolimited.com/auth/facebook";
    };

    return (
        <Box className="login-form" style={{ paddingTop: "65px" }}>
            <Typography variant="h5" className="login-title mb-5">
                Log In to Your Account
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="login-input"
                    margin="normal"
                />
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="login-input"
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                >
                    Log In
                </Button>
            </form>
            <Typography variant="body1" className="social-login-text">
                Or log in using:
            </Typography>
            <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={handleGoogleLogin}
                className="social-login-button google"
            >
                Google Login
            </Button>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleFacebookLogin}
                className="social-login-button facebook"
            >
                Facebook Login
            </Button>
        </Box>
    );
};

export default Login;
